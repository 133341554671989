<template>
  <div>
    <b-row>
      <b-col cols="12">
        <ListEpis />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import ListEpis from '@/views/epis/ListTable'

export default {
  components: {
    BRow,
    BCol,
    ListEpis,
  },
}
</script>
